* {
  font-size: 16px;
  padding: 0px;
  border: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}


h1 {
  font-size: 52px;
  font-weight: 700;
}

h2 {
  font-size: 42px;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

h3 {
  font-size: 33px;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  h3 {
    text-align: center;
  }
}

h4 {
  font-size: 26px;
  font-weight: 700;
}

h5 {
  font-size: 20px;
  font-weight: 700;
}

p {
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: none;

}

.orange {
  color: #FF6D70;
}

.orange-background {
  background-color: #FF6D70;
}

.orange-border {
  border: 1px solid #FF6D70;
}

.green {
  color: #1B5832;
}

.green-background {
  background-color: #1B5832;
}

.green-border {
  border: 1px solid #1B5832;
}

.white {
  color: white;
}

.white-background {
  background-color: white;
}

.white-border {
  border: 1px solid white;
}

.black {
  color: black;
}

.black-background {
  background-color: black;
}

.black-border {
  border: 1px solid black;
}

/* Header Styles */
header {
  background-color: black;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: row;
}

header > article {
  width: 100%;
}

header > .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 10px;
}

.logo {
  width: 60px;
  height: 60px;
}

header > .center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

@media only screen and (max-width: 700px) {
  header > .center {
    display: none;
  }
}

header > .center span {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
}

header > .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
}

header > article > .logout {
  width: 200px;
  height: 45px;
  color: #FF6D70;
  background: #FFFFFF;
  border: 1px solid #FF6D70;
  border-radius: 10px;
}

/* Footer styles */
footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: black;
}

footer p {
  color: #FFFFFF;
}

/* Generic page styles */
.page {
  width: 100%;
}

.page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 80px;
  gap: 40px;
  background-color: #1B5832;
}

@media only screen and (max-width: 800px) {
  .page-content {
    padding: 40px 40px;
  }
}

@media only screen and (max-width: 700px) {
  .page-content {
    align-items: center;
  }
}

.loading-page-content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1B5832;
}

.loading-page-content > p {
  width: 100%;
  text-align: center;
}

.emissions-summary-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.emissions-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 20px;
}

/* Generic button style */
button {
  width: 250px;
  height: 45px;
  cursor: pointer;
  border-radius: 10px;
}

.back-button {
  width: fit-content;
  height: fit-content;
  padding: 10px;
  background: none;
  border: none;
}

.back-button:hover {
  background: none;
  border: none;
}

/* Login styles */
.login-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 80px;
  gap: 40px;
  background-color: #1B5832;
}

.login-header > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 10px;
}

.big-logo {
  width: 200px;
  height: 200px;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  gap: 20px;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
}

.login > article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.login > article > input {
  height: 45px;
  padding: 0px 10px;
  width: 250px;
  border-radius: 10px;
}

/* Card list */
.card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 0px;
  gap: 40px;
}

@media only screen and (max-width: 800px) {
  .card-list {
    gap: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .card-list {
    justify-content: center;
  }
}

/* Event card */
.event-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 40px;
  gap: 20px;
  background: #FFFFFF;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  cursor: pointer;
}

.event-card > .card-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  border-radius: 10px 10px 0px 0px;
}

.event-card > .emissions-total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
}

/* Admin card */
.admin-emission-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 10px;
  gap: 40px;
  background: #FFFFFF;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.admin-emission-card > svg {
  height: 150px;
  width: auto;
}

/* Input Card */
.input-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  gap: 20px;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
}

.input-card > svg {
  height: 100px;
  width: auto;
}

.input-card > .event-space-name {
  background: none;
  text-align: center;
  width: 100%;
  font-size: 26px;
  font-weight: 700; 
}

.input-card > .event-space-name:focus {
  outline: none;
  background: none
}

.input-card > .input-article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.input-card > .input-article > input {
  padding-left: 10px;
  width: 250px;
  height: 45px;
  border-radius: 10px;
}

.input-card > .checkbox-container-article {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.input-card > .checkbox-container-article > .checkbox-article {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
}

.input-card > .dropdown-container-article {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.input-card > .dropdown-container-article > .dropdown-article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.input-card > .dropdown-container-article > .dropdown-article > select {
  width: 120px;
  height: 45px;
  border-radius: 10px;
  padding-left: 10px;
}


/* Add new card styles */
.add-new-card {
  width: 300px;
  height: 466px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  background: none;

}

.add-new-card > .plus-button {
  font-size: 52px;
}